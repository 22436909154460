




























import { trimSpaceToUnderscore } from "@/helpers/common";
import { debounceProcess } from "@/helpers/debounce";
import MNotificationVue from "@/mixins/MNotification.vue";
import { IArDataResponse } from "@interface/account-receivables.interface";
import { IOption } from "@/models/interface-v2/common.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { invoicePrepayment } from "@/services-v2/invoice-prepayment.service";
import Vue from "vue";
export default Vue.extend({
  name: "CSelectInvoiceCreditMemo",
  mixins: [
    MNotificationVue,
  ],
  props: {
    value: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    receiptArForm: {
      type: Object as () => {
        branchWarehouseId: string,
        currencyId: string,
        customerId: string,
        invoiceType: "Invoice AR" | "Credit Memo" | "Prepayment",
      },
      default: undefined,
    },
    arReceiptDetail: {
      type: Object as () => IArDataResponse,
      default: undefined,
    }
  },
  data() {
    this.getList = debounceProcess(this.getList, 300);
    return {
      dtOpt: [] as IOption[],
      loading: false,
    };
  },
  watch: {
    "receiptArForm.currencyId"(newval, oldval): void {
      if (newval && newval !== oldval) {
        this.getList();
      }
    },
    "receiptArForm.customerId"(newval, oldval): void {
      if (newval && newval !== oldval) {
        this.getList();
      }
    },
    "receiptArForm.invoiceType"(newval, oldval): void {
      if (newval && newval !== oldval) {
        this.getList();
      }
    },
    "receiptArForm.branchWarehouseId"(newval, oldval): void {
      if (newval && newval !== oldval) {
        this.getList();
      }
    },
    arReceiptDetail(): void {
      this.handleMapPrepayment();
    }
  },
  created(): void {
    this.getList();
  },
  methods: {
    async getList(docNumber?: string): Promise<void> {
      try {
        const param: RequestQueryParamsModel = {
          page: 0,
          limit: 20,
          search: `branch.secureId~${this.receiptArForm.branchWarehouseId}_AND_remainingAmount>0_AND_invoicePrepaymentStatus~UN_APPLIED_AND_invoiceType~${trimSpaceToUnderscore(this.receiptArForm.invoiceType)}`,
        };
        if (docNumber) {
          param.search += `_AND_documentNumber~*${docNumber}*`;
        }

        if (this.receiptArForm.customerId) {
          param.search += `_AND_customer.secureId~${this.receiptArForm.customerId}`;
        }
        
        const res = await invoicePrepayment.getListInvoicePrepayment(param);
        this.dtOpt = res.data.map(x => ({ key: x.invoicePrepaymentNumber, value: x.id, meta: x }));
      } catch (error) {
        console.error(error);
        this.showErrorMessage("notif_process_fail");
      }
    },
    onSelectInvoiceNumber(value, meta): void {
      this.$emit("input", value);
      this.$emit("on-select", { value, meta });
    },
    /**
     * map invoice prepayment id into
     * otions.
     */
    handleMapPrepayment(): void {
      this.arReceiptDetail.receiptLines.forEach(x => {
        const temp = x.applyPrepayment ?
          x.applyPrepayment.prepaymentLines.map((dataMap) => {
            return {
              ...dataMap,
              name: dataMap.invoicePrepaymentNo,
              id: dataMap.invoicePrepaymentId || "",
              remainingAmount: dataMap.appliedAmount
            };
          }) : [];
          this.dtOpt.push(
            ...temp.map(y => ({
              key: y.invoicePrepaymentNo || "",
              value: y.invoicePrepaymentId,
            }))
          );

          // cek duplicate
          this.dtOpt = this.dtOpt.filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.value === value.value
            ))
          );
      });
    },
  }
});
